import React from 'react'
import Page from '@/components/Page'
import PageWrapper from '@/components/PageWrapper'
import TextIntro from '@/components/TextIntro'
import { pageFadeInOut } from '@/modules/animationVariants'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const Index: React.FC = () => {
  const { t } = useTranslation()

  const context = {
    titleText: t(
      'our-platform.home_header',
      'fill copy key: our-platform.home_header',
    ),
    paragraphText: t(
      'our-platform.home_body',
      'fill copy key: our-platform.home_body',
    ),
  }

  return (
    <Page
      content={
        <PageWrapper>
          <TextIntro {...context} />
        </PageWrapper>
      }
      settings={{
        backgroundColor: '#5a7d69',
        contentAnimationVariants: pageFadeInOut(),
        useCircularWipe: false,
      }}
    />
  )
}

export default Index
